<template>
<div class="modal fade" id="requestModal" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <h3 class="title-main bold-weight mb-3">Unverbindliche Anfrage</h3>
        <div class="row">
          <div class="col-sm-6 mb-3">
            <input type="text" v-model.trim="form.name" class="form-control" required placeholder="Name / Firma *" :class="{ 'is-invalid': form.errors.has('name') }">
            <div v-if="form.errors.has('name')" class="invalid-feedback">{{ form.errors.get('name') }}</div>
          </div>
          <div class="col-sm-6 mb-3">
            <input v-model="form.address" type="text" class="form-control" required placeholder="Strasse und Hausnummer *" :class="{ 'is-invalid': form.errors.has('address') }">
            <div v-if="form.errors.has('address')" class="invalid-feedback">{{ form.errors.get('address') }}</div>
          </div>
          <div class="col-sm-6 mb-3">
            <input v-model="form.city" type="text" class="form-control" required placeholder="PLZ, Stadt *" :class="{ 'is-invalid': form.errors.has('city') }">
            <div v-if="form.errors.has('city')" class="invalid-feedback">{{ form.errors.get('city') }}</div>
          </div>
          <div class="col-sm-6 mb-3">
            <input v-model.trim="form.phone" type="text" class="form-control" required placeholder="Telefonnummer *" :class="{ 'is-invalid': form.errors.has('phone') }">
            <div v-if="form.errors.has('phone')" class="invalid-feedback">{{ form.errors.get('phone') }}</div>
          </div>
          <div class="col-sm-6 mb-3">
            <input v-model.trim="form.email" type="text" class="form-control" required placeholder="Email *" :class="{ 'is-invalid': form.errors.has('email') }">
            <div v-if="form.errors.has('email')" class="invalid-feedback">{{ form.errors.get('email') }}</div>
          </div>
          <div class="col-12 mb-3">
            <label class="form-label">Weitere Informationen</label>
            <textarea v-model="form.message" rows="5" class="form-control" placeholder="Ergänzungen rund um Ihre Anfrage und besondere Wünsche schreiben Sie bitte hier in das Freitexfeld."></textarea>
         </div>
      </div>
      <p class="mb-3">Nach Eingang Ihrer Anfrage werden wir Ihnen ein individuelles Angebot zusenden.</p>
        <div class="col-12 mb-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="form.rules" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">Ich habe die <a href="https://www.loewe-zaun.de/datenschutz/" target="_blank">Datenschutzerklärung</a> gelesen und bin damit einverstanden, dass meine Kontaktdaten für die Angebotserstellung verwendet werden. *</label>
          </div>
          <span v-if="!form.rules" class="text-danger">Die Datenschutzerklärung muss akzeptiert werden</span>
        </div>
      <div class="row mt-3">
            <div v-if="sending" class="alert alert-success">{{ formMsg }}</div>
            <div class="w-auto mb-2">
              <button type="button" class="btn btn-outline-primary min-width-170" data-bs-dismiss="modal">Schließen</button>
            </div>
            <div class="w-auto mb-2">
              <button type="button" class="btn btn-primary min-width-170" :class="status ? 'disabled': ''" @click="sendRequest">
                Anfrage absenden
              </button>
            </div>
      </div>
      <p class="mb-3">Sie interessieren sich für folgendes Produkt:</p>
        <div class="product-param product-param--modal">
          <div class="title-secondary bold-weight mb-3">{{ summary.name }}</div>
            <div v-for="step in steps" :key="step.id" class="param-item row">
              <div class="param-item__name col-sm-4 col-5">{{ step.name }}:</div>
              <div class="param-item__value col-sm-8 col-7">{{ step.selectedLabel}}</div>
            </div>
            <div class="text-end">
              <div class="total-price total-price--discount">{{ $filters.currency(price.discounted) }}</div>
              <div class="total-price total-price--old mt-1">{{ $filters.currency(price.old_price) }}</div>
            </div>
          </div>
        </div>
       </div>
    </div>
</div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import axios from 'axios'
import Form from 'vform'
// import { useRouter } from 'vue-router'
export default {
  setup () {
    // const router = useRouter()
    const form = ref(new Form({
      name: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      message: '',
      rules: true
    }))
    const errors = ref({})
    const formMsg = ref(null)
    const sending = ref(false)
    const status = ref(false)
    // computed
    const store = useStore()
    const summary = computed(() => {
      return store.getters['configurator/summary']
    })
    const show = computed(() => {
      return store.getters['configurator/showRequestForm']
    })
    const price = computed(() => {
      return store.getters['configurator/price']
    })
    const steps = computed(() => {
      return store.getters['configurator/navigation']
    })
    const formData = computed(() => {
      return {
        ...form.value,
        summary: summary.value
      }
    })
    // methods
    async function sendRequest () {
      if (!this.form.rules) {
        return
      }
      sending.value = false
      status.value = true
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const fd = new FormData()
      fd.append('re', apiKey)
      if (store.getters['stats/referrerUrl'] !== null) {
        fd.append('referrer_url', store.getters['stats/referrerUrl'])
        fd.append('referrer', store.getters['stats/referrer'])
      }
      for (const key in formData.value) {
        if (key === 'summary') {
          fd.append('summary', JSON.stringify(formData.value[key]))
        } else {
          fd.append(key, formData.value[key])
        }
      }
      const apiUrl = process.env.VUE_APP_API_URL
      await axios.post(apiUrl + 'quickorder', fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(resp => {
        if (resp.data) {
          sending.value = true
          status.value = false
          formMsg.value = 'Vielen Dank für Ihre Anfrage und das damit verbundene Interesse an unserem Unternehmen. Ein Mitarbeiter von der Verkaufs-Abteilung wird sich mit Ihnen kurzfristig in Verbindung setzen.'
          setTimeout(() => {
            sending.value = false
            window.location.href = process.env.VUE_APP_BASE_URL + '/thanks'
            // router.push({ name: 'configurator.thanks' })
          }, 5000)
        }
      }).catch((errors) => {
        status.value = false
        form.value.errors.successful = false
        const formErrors = errors.response.data.errors
        form.value.errors.errors = formErrors
        errors.value = errors.resp.data
      })
    }
    return {
      form,
      errors,
      formMsg,
      sending,
      status,
      summary,
      show,
      price,
      steps,
      sendRequest
    }
  }
}
</script>
