<template>
    <footer class="footer">
        <div class="container">
            <div v-if="footer" class="row">
                <div class="col-lg-3 footer__col footer__col--logo">
                    <img src="@/assets/img/logo_footer.svg" alt="" class="img-fluid">
                </div>
                <div v-for="(item, index) in footer" :key="index" class="col-lg-3 col-sm-4 footer__col">
                    <div class="title-secondary bold-weight mb-0 mb-sm-3">{{ item.name }}</div>
                    <ul v-if="item.boxes.length > 0" class="footer__menu">
                        <li v-for="(link, i) in item.boxes" :key="i">
                          <a :href="link.url" class="footer__menu-item" :target="link.target === null ? link.target : '_self'">{{ link.name }}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 col-sm-4 footer__col">
                    <div class="title-secondary bold-weight mb-0 mb-sm-3">Beratung</div>
                    <div class="contact-box">
                        <a href="tel:+493031007700" class="contact-box__link mb-2"><i class="fas fa-phone-alt"></i> 030 / 31007700</a>
                        <a href="mailto:info@loewe-pergola.de" class="contact-box__link"><i class="fas fa-envelope"></i> info@loewe-pergola.de</a>
                    </div>
                </div>
            </div>
            <div class="footer__copyright">© 2025 LÖWE – Der Experte für Terrassenüberdachungen</div>
        </div>
    </footer>
</template>
<script>
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
export default {
  name: 'FooterPartial',
  setup () {
    const store = useStore()
    onMounted(() => {
      store.dispatch('footer/setFooter')
    })
    const footer = computed(() => {
      return store.getters['footer/footer']
    })

    return {
      footer
    }
  }
}
</script>
